<template>
    <v-container id="secEquipmentGrid">
        <h1
            class="text-h6 text-md-h4 font-weight-black mt-10 mb-12 text-center text-uppercase font-weight-bold"
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="100"
            data-aos-offset="300"
        >
            Equipos de 
            <span class="primary--text">
                fresado
            </span>
        </h1>
        <v-container class="">
            <v-row no-gutters class="d-flex flex-column flex-sm-row justify-center align-center row-width">
                <v-col 
                    class="grid-responsive"
                    v-for="(item, i) in items" :key="i"
                    cols="10"
                    sm="6"
                    lg="4"
                >
                    <v-card
                        class="mx-3 my-xs-3 w-responsive"
                    >
                        <v-img
                            :src="item.img"
                            data-aos="flip-left"
                            data-aos-duration="2500"
                            data-aos-delay="100"
                            data-aos-offset="300"
                            data-aos-easing="ease-in-sine"
                        />
                        <v-card-title
                            class="primary white--text text-uppercase mb-4"
                            data-aos="flip-left"
                            data-aos-duration="2500"
                            data-aos-delay="100"
                            data-aos-offset="300"
                            data-aos-easing="ease-in-sine"
                        >
                            <div class="card-equip">
                                <div class="title-equip">
                                    <div class="line-equip"></div>
                                    <div class="title-text">
                                        <h3 class="text-center font-weight-black text-subtitle-1 text-md-h6 text-xl-h5">{{ item.marca }}</h3>
                                        <h3 class="text-center font-weight-black text-subtitle-1 text-md-h6 text-xl-h5">{{ item.modelo }}</h3>
                                    </div>
                                    <div class="line-equip"></div>
                                </div>
                                <div class="descrip-equip px-1 px-sm-4 px-lg-6">
                                    <h4 class="font-weight-black text-white text-subtitle-2 text-md-subtitle-1 text-xl-h5 space-pre">Ancho de corte</h4>
                                    <div class="line-equipment"></div>
                                    <p class="">{{ item.width }}</p>
                                    <h4 class="font-weight-black text-white text-subtitle-2 text-md-subtitle-1 text-xl-h5 space-pre">espesor máximo de fresado</h4>
                                    <div class="line-equipment"></div>
                                    <p class="">{{ item.density }}</p>
                                </div>
                            </div>
                        </v-card-title>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
export default {
    name: 'SectionEquipmentGrid',
    data() {
        return {
            items: [
                {
                    img: require('@/assets/img/img-wirtgen-1000.jpg'),
                    marca: 'Wirtgen',
                    modelo: 'W 1000',
                    width: '1.00 m.',
                    density: '20 cm.'
                },
                {
                    img: require('@/assets/img/img-wirtgen-2000.jpg'),
                    marca: 'Wirtgen',
                    modelo: 'W 2000',
                    width: '2.00 m.',
                    density: '30 cm.'
                },
                {
                    img: require('@/assets/img/img-wirtgen-100.jpg'),
                    marca: 'Wirtgen',
                    modelo: 'W 100',
                    width: '1.00 m.',
                    density: '30 cm.'
                },
                {
                    img: require('@/assets/img/img-wirtgen-150.jpg'),
                    marca: 'Wirtgen',
                    modelo: 'W 150',
                    width: '1.50 m.',
                    density: '30 cm.'
                },
                {
                    img: require('@/assets/img/img-caterpillar.jpg'),
                    marca: 'CATERPILLAR',
                    modelo: 'PR-450',
                    width: '2.00 m.',
                    density: '25 cm.'
                },
            ],
        }
    },
}
</script>

<style lang="scss">
#secEquipmentGrid {
    .row-width { padding: 0 10vw; }
    .card-equip {
        .text-eq-home { padding: 0 22vw; }
        .title-equip {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 2vw;
            .title-text { padding: 0 1vw; }
            .line-equip {
                height: 4vw;
                width: 3px;
                background-color: white;
                transform: skewX(160deg);
            }
        }
        .descrip-equip { 
            margin-top: 2vw;
            .space-pre { word-break: keep-all; }
            .line-equipment {
                position: relative;
                border-top: 1px solid;
                width: 8%;
            }
        }
        @media (max-width: 1024px) {
            .descrip-equip {
                padding: 0;
            }
        }
        @media (max-width: 800px) and (min-width: 501px) {
            .title-equip {
                .line-equip {
                    height: 8vw;
                }
            }
        }
        @media (max-width: 500px) {
            .title-equip {
                .line-equip {
                    height: 12vw;
                }
            }   
        }
    }
}
</style>